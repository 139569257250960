import React from "react";
import SEO from "../components/organisms/seo";
import EMail from "../components/modules/content/email";

const NewsletterPage = () => (
  <div className="spacing-top-big">
    <SEO siteTitle="The Solution" />
    <EMail title="Receive our quarterly newsletter" />
  </div>
);

export default NewsletterPage;
